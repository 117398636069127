import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/main-style.scss'
import { BrowserRouter as Router } from 'react-router-dom'
import './assets/styles/loading.scss'
import 'react-multi-carousel/lib/styles.css';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loading from './components/Loading';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const App = React.lazy(() => import('./App'));
root.render(
  <React.StrictMode>
    <Suspense fallback = {<Loading/>}>
    <Router>
      <App />
      <ToastContainer/>
    </Router>
    </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
